import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'app-first-screen-support',
    templateUrl: './first-screen-support.component.html',
    styleUrls: ['./first-screen-support.component.scss']
})
export class FirstScreenSupportComponent implements OnInit {

    public cards = [
        {
            id: 'haulmer-cs-tuu',
            icon: 'ic-sprite-tuu',
            size: 32,
            text: 'Cuéntanos si tienes dudas o problemas con el uso de tu máquina de pagos.',
            name: 'TUU',
            url: 'https://www.tuu.cl/contactar-con-soporte'
        },
        {
            id: 'haulmer-cs-of',
            icon: 'ic-sprite-openfactura',
            size: 34,
            text: 'Te ayudaremos si tienes algún problema con tu facturación electrónica.',
            name: 'Openfactura',
            url: 'https://www.openfactura.cl/contactar-con-soporte/'
        },
        {
            id: 'haulmer-cs-cf',
            icon: 'ic-sprite-chilefirmas',
            size: 34,
            text: 'Te ayudaremos si tienes dudas para usar tu firma electrónica.',
            name: 'Chilefirmas',
            url: 'https://www.chilefirmas.cl/contactar-con-soporte/'
        },
        {
            id: 'haulmer-cs-bh',
            icon: 'ic-sprite-bluehosting',
            size: 38,
            text: 'Habla con nuestro equipo de expertos en hosting.',
            name: 'Bluehosting',
            url: 'https://www.bluehosting.cl/contactar-con-soporte/'
        },
        {
            id: 'haulmer-cs-oc',
            icon: 'ic-sprite-opencloud',
            size: 44,
            text: 'Habla con nuestro equipo de expertos en máquinas virtuales.',
            name: 'Opencloud',
            url: 'https://www.opencloud.cl/contactar-con-soporte/'
        }
    ]

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit(): void {
    }

    public goToUrl(url: string): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

}
