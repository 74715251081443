import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'app-section-vacancies',
    templateUrl: './section-vacancies.component.html',
    styleUrls: ['./section-vacancies.component.scss']
})
export class SectionVacanciesComponent {
    @Input() public loadingImages: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    public goToUrl(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open('https://www.linkedin.com/company/haulmer/', '_blank');
        }
    }
}
