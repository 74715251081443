import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { URL } from '../../environments/environment';

@Component({
    selector: 'app-contact-support',
    templateUrl: './contact-support.component.html'
})
export class ContactSupportComponent implements OnInit, OnDestroy {
    public URL = URL;
    public showImage1 = false;

    constructor(
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService
    ) { }

    ngOnInit(): void {
        this._setMeta();
        this.renderer.addClass(document.body, 'contact');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'contact');
    }

    private _setMeta(): void {
        this._meta.setTitle('Soporte Haulmer, TUU, Openfactura, Bluehosting');
        this._meta.setTag('description', 'Soporte Haulmer para TUU, Openfactura, Bluehosting, Chilefirmas, Opencloud');
        this._meta.setTag('keywords', 'Soporte, Haulmer, TUU, Openfactura, Bluehosting, Chilefirmas, Opencloud, Pagos con tarjeta, boleta electrónica, inventario, máquina POS, POS, punto de venta, emprendedores');
        this._meta.setTag('author', 'Haulmer');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HAULMER_WEBSITE + 'assets/images/og/og-support.png');
        this._meta.setTag('og:image:secure_url', this.URL.HAULMER_WEBSITE + 'assets/images/og/og-support.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Soporte Haulmer, TUU, Openfactura, Bluehosting');
    }

}
