import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-brands',
    templateUrl: './section-brands.component.html',
    styleUrls: ['./section-brands.component.scss']
})
export class SectionBrandsComponent {
    @Input() public loadingImages: boolean;
}
