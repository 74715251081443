import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AnimationBuilder, AnimationPlayer, animate, keyframes, style } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

interface IHistory {
    year: number;
    title: string;
    descr: string;
}

interface ISliderProps {
    parent: HTMLElement;
    container: HTMLElement;
    items: HTMLCollection;
    itemWidth: number;
    itemHeight: number;
    lastItem?: HTMLElement;
    count: number;
    gap: number;
    drag: { pressed: boolean; startPosition: number; newPosition: number }
}

@Component({
    selector: 'app-section-history',
    templateUrl: './section-history.component.html',
    styleUrls: ['./section-history.component.scss'],
})
export class SectionHistoryComponent implements AfterViewInit, OnDestroy {
    @ViewChild('slider') public slider: ElementRef;
    @ViewChild('nav') public nav: ElementRef;

    public historyArr: Array<IHistory> = [
        {
            year: 2010,
            title: 'Inicios',
            descr: 'Se crea Bluehosting, primer web hosting en Chile en ofrecer espacio ilimitado y a un precio 50% más bajo que sus competidores.',
        }, {
            year: 2014,
            title: 'Ampliamos fronteras',
            descr: 'Se constituye la empresa y comienza a vender servicios Web Hosting en Perú y Colombia.',
        }, {
            year: 2015,
            title: 'Creamos Opencloud',
            descr: 'Primera empresa en Chile en comercializar Máquinas virtuales a bajo costo con data center propio.',
        }, {
            year: 2017,
            title: 'Nace Haulmer',
            descr: 'Comenzamos con la misión de llevar un dispositivo tecnológico a los emprendedores del rubro del retail y se incorporan los socios Miguel, JM y Enrique.',
        }, {
            year: 2018,
            title: 'Lanzamos Openfactura',
            descr: 'Primera plataforma de facturación electrónica con emisión ilimitada de documentos electrónicos.',
        }, {
            year: 2019,
            title: 'Nace Simpleboleta',
            descr: 'Servicio de emisión de boleta electrónica a través de una app o máquina pos.',
        }, {
            year: 2021,
            title: 'Creamos TUU',
            descr: 'Servicio todo en uno para recibir pagos con tarjetas, emitir boletas electrónicas ilimitadas, punto de venta con catálogo e inventario, todo en nuestra máquina POS TUU.',
        },
    ];

    public sliderProps: ISliderProps = {
        parent: null,
        container: null,
        items: null,
        itemWidth: 0,
        itemHeight: 0,
        lastItem: null,
        count: 0,
        gap: 0,
        drag: { pressed: false, startPosition: 0, newPosition: 0 },
    };

    public isNextDisabled = false;
    public isPrevDisabled = true;

    public sliderPlayer: AnimationPlayer | undefined;
    public stream$: Observable<unknown>;

    @HostListener('window:resize')
    onResize() {
        if (isPlatformBrowser(this.platformId)) {
            this.initSlider();
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: unknown,
        private _builder: AnimationBuilder,
    ) { }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.initSlider();

            // prevents clicking on the button until the animation ends
            this.stream$ = fromEvent(
                [
                    this.nav.nativeElement.getElementsByClassName('next')[0],
                    this.nav.nativeElement.getElementsByClassName('prev')[0],
                ], 'click',
            ).pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap((v: any) => {
                    if (v.target.closest('BUTTON') && v.target.closest('BUTTON').classList.contains('next')) {
                        this.moveSlider('next');
                    } else if (v.target.closest('BUTTON') && v.target.closest('BUTTON').classList.contains('prev')) {
                        this.moveSlider('prev')
                    }
                }),
            );
            this.stream$.subscribe();
        }
    }

    public ngOnDestroy(): void {
        this.sliderPlayer?.destroy();
    }

    public initSlider(): void {
        this.sliderProps.parent = this.slider.nativeElement;
        this.sliderProps.container = this.slider.nativeElement.getElementsByClassName('inner-ts')[0];
        this.sliderProps.items = this.slider.nativeElement.getElementsByClassName('item-ts');
        this.sliderProps.lastItem = this.slider.nativeElement.getElementsByClassName('item-ts')[this.historyArr.length - 1];
        this.slider.nativeElement.getElementsByClassName('item-ts')[0].classList.add('active');
        this.sliderProps.itemWidth = 320;
        this.sliderProps.itemHeight = 340;
        this.sliderProps.gap = 16;
    }

    public moveSlider(direction: string): void {
        if (isPlatformBrowser(this.platformId)) {
            const lastItemRect = this.sliderProps.lastItem.getBoundingClientRect();
            const parentRect = this.sliderProps.parent.getBoundingClientRect();

            if (direction === 'prev' && !this.isPrevDisabled) {
                this.isNextDisabled = false;
                this.sliderProps.count--;
                this.slider.nativeElement.getElementsByClassName('item-ts')[this.sliderProps.count + 1].classList.remove('active');
                this.slider.nativeElement.getElementsByClassName('item-ts')[this.sliderProps.count].classList.add('active');

                this.animateSlides('prev');

                this.isPrevDisabled = this.sliderProps.count === 0;
            } else if (direction === 'next' && !this.isNextDisabled) {
                this.isPrevDisabled = false;
                this.sliderProps.count++;
                this.slider.nativeElement.getElementsByClassName('item-ts')[this.sliderProps.count - 1].classList.remove('active');
                this.slider.nativeElement.getElementsByClassName('item-ts')[this.sliderProps.count].classList.add('active');

                this.animateSlides('next');

                this.isNextDisabled = (lastItemRect.right - this.sliderProps.itemWidth - this.sliderProps.gap) < parentRect.width;
            }
        }
    }

    public animateSlides(direction: string): void {
        const newPosition = this.sliderProps.count * (this.sliderProps.itemWidth + this.sliderProps.gap);

        for (let index = 0; index < this.sliderProps.items.length; index++) {
            let transition: string;

            if (direction === 'next') {
                transition = '.5s ' + index / 30 + 's ease-in-out';
            } else if (direction === 'prev') {
                transition = '.5s ' + (this.sliderProps.items.length - index) / 30 + 's ease-in-out';
            }

            const currentAnimate = animate(transition, keyframes([
                style({
                    transform: 'translateX(-' + newPosition + 'px)',
                }),
            ]));

            this.sliderPlayer = this._builder.build(currentAnimate).create(this.sliderProps.items[index]);
            this.sliderPlayer.play();
        }
    }
}
