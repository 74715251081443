import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-benefits',
    templateUrl: './section-benefits.component.html',
    styleUrls: ['./section-benefits.component.scss'],
})
export class SectionBenefitsComponent {
    @Input() public loadingImages: boolean;

    public benefits = [
        {
            icon: 'ic-sprite-clock',
            caption: '40 Horas de trabajo',
        },
        {
            icon: 'ic-sprite-hourglass-end',
            caption: 'Horario laboral flexible',
        },
        {
            icon: 'ic-sprite-laptop',
            caption: 'Modalidad de teletrabajo',
        },
        {
            icon: 'ic-sprite-glass-cheers',
            caption: 'Actividades recreativas',
        },
        {
            icon: 'ic-sprite-cake-birthday',
            caption: 'Día libre cumpleaños',
        },
        {
            icon: 'ic-sprite-ambulance',
            caption: 'Seguro complementario Haulmer',
        },
        {
            icon: 'ic-sprite-rocket',
            caption: 'Convenio Caja Los Andes',
        },
        {
            icon: 'ic-sprite-child-head',
            caption: 'Apoyo nacimiento',
        },
        {
            icon: 'ic-sprite-rings-wedding',
            caption: 'Apoyo matrimonio',
        },
        {
            icon: 'ic-sprite-bed',
            caption: 'Apoyo defunción',
        },
    ];
}
