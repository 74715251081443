import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';

interface IReview {
    client: string;
    name?: string;
    text?: string;
    preview: string;
    video?: string;
}

interface ISliderProps {
    parent: any;
    container: any;
    itemWidth: number;
    itemHeight: number;
    lastItem?: any;
    gap: number;
    drag: { pressed: boolean; startPosition: number; newPosition: number}
}

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('slider') public slider: ElementRef;
    @Output() public sendVideo = new EventEmitter<string>();
    @Input() public loadingImages: boolean;

    public start: (any) => void;
    public move: (any) => void;
    public stop: () => void;
    public isNextDisabled = false;
    public isPrevDisabled = true;

    public reviews: Array<IReview> = [
        {
            client: 'Noor Papelería',
            text: '',
            preview: 'noor-papeleria',
            video: 'noor-papeleria'
        },
        {
            client: 'Kosmetic Store',
            preview: 'kosmetic-store',
            video: 'kosmetic-store'
        },
        {
            client: 'Cabritalegre',
            preview: 'cabritalegre',
            video: 'cabritalegre'
        },
        {
            client: 'Amapola Veterinaria',
            preview: 'amapola-veterinaria',
            video: 'amapola-veterinaria'
        },
        {
            client: 'Sport 360',
            preview: 'sport-360',
            video: 'sport-360'
        }
    ];

    public sliderProps: ISliderProps = {
        parent: null,
        container: null,
        itemWidth: 0,
        itemHeight: 0,
        lastItem: null,
        gap: 0,
        drag: { pressed: false, startPosition: 0, newPosition: 0 }
    };

    @HostListener('window:resize')
    onResize() {
        if (isPlatformBrowser(this.platformId)) {
            this.initSlider();
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.initSlider();
        }
    }

    public initSlider(): void {
        this.sliderProps.parent = this.slider.nativeElement;
        this.sliderProps.container = this.slider.nativeElement.getElementsByClassName('inner-ts')[0];
        this.sliderProps.lastItem = this.slider.nativeElement.getElementsByClassName('item-ts')[this.reviews.length - 1]
        this.sliderProps.itemWidth = window.innerWidth > 670 ? 420 : 210;
        this.sliderProps.itemHeight = window.innerWidth > 670 ? 560 : 280;
        this.sliderProps.gap = 15;
        this.sliderProps.container.style.width =
            this.sliderProps.itemWidth * (this.reviews.length) +
            (this.sliderProps.gap * (this.reviews.length - 1)) + 'px';

        this.dragging();
    }

    public dragging(): void {
        this.start = (e) => {
            this.sliderProps.drag.pressed = true;
            this.sliderProps.drag.startPosition = (e.pageX || e.touches[0].clientX) - (this.sliderProps.container.getBoundingClientRect().left - 40);
            this.sliderProps.container.style.cursor = 'grabbing';
        }

        this.move = (e) => {
            if (!this.sliderProps.drag.pressed) return;
            e.preventDefault();

            const currentX = (e.pageX || e.touches[0].clientX) < 0 ? 0 : (e.pageX || e.touches[0].clientX);
            const dist = currentX - (this.sliderProps.parent.getBoundingClientRect().left - 40);
            this.sliderProps.drag.newPosition = dist - this.sliderProps.drag.startPosition;
            this.sliderProps.container.style.transform = `translateX(${this.sliderProps.drag.newPosition}px)`;

            this._checkBoundary();
        }

        this.stop = () => {
            this.sliderProps.container.style.cursor = 'grab';
            this.sliderProps.drag.pressed = false;
        }

        this.sliderProps.container.addEventListener('mousedown', this.start, false);
        this.sliderProps.container.addEventListener('mousemove', this.move, false);
        this.sliderProps.container.addEventListener('mouseup', this.stop, false);

        // touch event
        this.sliderProps.container.addEventListener('touchstart', this.start, false);
        this.sliderProps.container.addEventListener('touchmove', this.move, false);
    }

    public moveSlider(direction: string): void {
        if (isPlatformBrowser(this.platformId)) {
            const lastItemRect = this.sliderProps.lastItem.getBoundingClientRect();
            const parentRect = this.sliderProps.parent.getBoundingClientRect();

            if (direction === 'prev') {
                if (this.sliderProps.drag.newPosition < 0) {
                    if (Math.round(this.sliderProps.drag.newPosition / (this.sliderProps.itemWidth + this.sliderProps.gap)) > -2) {
                        this.sliderProps.drag.newPosition = 0;
                    } else {
                        this.sliderProps.drag.newPosition = this.sliderProps.itemWidth + this.sliderProps.gap + this.sliderProps.drag.newPosition;
                    }
                    this.isNextDisabled = false;
                }
                this.isPrevDisabled = this.sliderProps.drag.newPosition === 0;
            } else if (direction === 'next') {
                if (lastItemRect.right > parentRect.width) {
                    this.sliderProps.drag.newPosition = -(this.sliderProps.itemWidth + this.sliderProps.gap) + this.sliderProps.drag.newPosition;
                    this.isPrevDisabled = false;
                }

                this.isNextDisabled = (lastItemRect.right - this.sliderProps.itemWidth - this.sliderProps.gap) < parentRect.width;
            }

            this.sliderProps.container.style.transform = 'translateX(' + this.sliderProps.drag.newPosition + 'px)';
        }
    }

    public openVideo(video: string): void {
        this.sendVideo.emit(video);
    }

    private _checkBoundary(): void {
        const parentBoundingRect = this.sliderProps.parent.getBoundingClientRect();
        const containerBoundingRect = this.sliderProps.container.getBoundingClientRect();

        if (this.sliderProps.drag.newPosition > 0) {
            this.sliderProps.drag.newPosition = 0;
            this.sliderProps.container.style.transform = `translateX(0px)`;
        }


        if ((containerBoundingRect.right + parentBoundingRect.left + 80) < parentBoundingRect.right) {
            this.sliderProps.drag.newPosition = -(containerBoundingRect.width - parentBoundingRect.width + parentBoundingRect.left + 80);
            this.sliderProps.container.style.transform = `translateX(${this.sliderProps.drag.newPosition}px)`;
        }
        this.isPrevDisabled = this.sliderProps.drag.newPosition >= 0;
        this.isNextDisabled = (containerBoundingRect.right + parentBoundingRect.left + 80) < parentBoundingRect.right;
    }

    ngOnDestroy(): void {
        this.sliderProps.container?.removeEventListener('mousedown', this.start, false);
        this.sliderProps.container?.removeEventListener('mousemove', this.move, false);
        this.sliderProps.container?.removeEventListener('mouseup', this.stop, false);
        this.sliderProps.container?.removeEventListener('touchstart', this.start, false);
        this.sliderProps.container?.removeEventListener('touchmove', this.move, false);
    }
}
