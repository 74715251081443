import { Component, HostListener, OnInit } from '@angular/core';

import { URL } from '../../../../environments/environment';
import { HeaderMenuService } from '../../services/header-menu.service';
import { IDropdown } from '../header-data';

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {
    public URL = URL;

    public currentData: IDropdown;
    public isShow: boolean;

    @HostListener('mouseleave', ['$event'])
    mouseleave() {
        this.isShow = false;

        setTimeout(() => {
            this._headerMenuService.setVisibilityDropdownMenu(false);
        }, 100);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth <= 992) {
            this._headerMenuService.setVisibilityDropdownMenu(false);
        }
    }

    constructor(
        private _headerMenuService: HeaderMenuService
    ) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.isShow = true;
        }, 0);
    }
}
