import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeCL from '@angular/common/locales/es-CL';
registerLocaleData(localeCL);

import { MetaModule, MetaService } from '@ngx-meta/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { DeferLoadModule } from '@trademe/ng-defer-load';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// home
import { HomeComponent } from './home/home.component';
import { FirstScreenHomeComponent } from './home/first-screen-home/first-screen-home.component';
import { ResourceTabsComponent } from './home/resource-tabs/resource-tabs.component';
import { AboutHaulmerComponent } from './home/about-haulmer/about-haulmer.component';
import { ReviewsComponent } from './home/reviews/reviews.component';
import { ModalVideoComponent } from './home/reviews/modal-video/modal-video.component';

import { ErrorPageComponent } from './error-page/error-page.component';

import { CustomMaterialModule } from './shared/material.module';

// header
import { HeaderComponent } from './shared/header/header.component';
import { HeaderNavComponent } from './shared/header/header-nav/header-nav.component';
import { DropdownMenuComponent } from './shared/header/dropdown-menu/dropdown-menu.component';
import { MobileMenuComponent } from './shared/header/mobile-menu/mobile-menu.component';
import { MobileMenuInnerComponent } from './shared/header/mobile-menu/mobile-menu-inner/mobile-menu-inner.component';

// footer
import { FooterComponent } from './shared/footer/footer.component';

// contact
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { FirstScreenSupportComponent } from './contact-support/first-screen-support/first-screen-support.component';
import { AboutProductComponent } from './contact-support/about-product/about-product.component';

// pipe
import { CurrencyLocalePipe } from './shared/pipe/currency.pipe';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { AboutUsFirstScreenComponent } from './about-us-page/about-us-first-screen/about-us-first-screen.component';
import { SectionHistoryComponent } from './about-us-page/section-history/section-history.component';
import { SectionBrandsComponent } from './about-us-page/section-brands/section-brands.component';
import { SectionOurValuesComponent } from './about-us-page/section-our-values/section-our-values.component';
import { SectionObjectivesComponent } from './about-us-page/section-objectives/section-objectives.component';
import { SectionFoundersComponent } from './about-us-page/section-founders/section-founders.component';
import { SectionHowWeWorkComponent } from './about-us-page/section-how-we-work/section-how-we-work.component';
import { SectionBenefitsComponent } from './about-us-page/section-benefits/section-benefits.component';
import { SectionVacanciesComponent } from './about-us-page/section-vacancies/section-vacancies.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HeaderNavComponent,
        DropdownMenuComponent,
        MobileMenuComponent,
        MobileMenuInnerComponent,
        ErrorPageComponent,
        HomeComponent,
        FirstScreenHomeComponent,
        ResourceTabsComponent,
        AboutHaulmerComponent,
        ReviewsComponent,
        ModalVideoComponent,
        FooterComponent,
        ContactSupportComponent,
        FirstScreenSupportComponent,
        AboutProductComponent,
        // About us page
        AboutUsPageComponent,
        AboutUsFirstScreenComponent,
        SectionHistoryComponent,
        SectionBrandsComponent,
        SectionOurValuesComponent,
        SectionObjectivesComponent,
        SectionFoundersComponent,
        SectionHowWeWorkComponent,
        SectionBenefitsComponent,
        SectionVacanciesComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'pos'}),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        CustomMaterialModule,
        IconSpriteModule.forRoot({
            path: '/assets/images/sprites/sprite.svg'
        }),
        DeferLoadModule,
        MetaModule.forRoot()
    ],
    providers: [
        MetaService,
        CurrencyLocalePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
