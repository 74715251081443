import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    }, {
        path: 'contactar-con-soporte/.',
        component: ContactSupportComponent
    }, {
        path: 'contactar-con-soporte',
        redirectTo: 'contactar-con-soporte/.',
        pathMatch: 'full'
    }, {
        path: 'quienes-somos/.',
        component: AboutUsPageComponent
    }, {
        path: 'quienes-somos',
        redirectTo: 'quienes-somos/.',
        pathMatch: 'full'
    }, {
        path: 'error/.',
        component: ErrorPageComponent
    }, {
        path: '**',
        redirectTo: 'error/.'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
