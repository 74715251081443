import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { URL } from '../../../../../environments/environment';
import { IDropdown } from '../../header-data';

@Component({
    selector: 'app-mobile-menu-inner',
    templateUrl: './mobile-menu-inner.component.html',
    styleUrls: ['./mobile-menu-inner.component.scss']
})
export class MobileMenuInnerComponent implements OnInit {
    @Output() closeEventChild = new EventEmitter<string>();

    public URL = URL;

    public isShow: boolean;
    public currentData: IDropdown;

    constructor(
    ) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.isShow = true;
        }, 0);
    }

    public close(): void {
        this.closeEventChild.emit('close');
    }

    public back(): void {
        this.isShow = false;

        setTimeout(() => {
            this.closeEventChild.emit('back');
        }, 400);
    }

}
