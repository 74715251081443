import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { HeaderMenuService } from '../../services/header-menu.service';

@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent {

    constructor(
        private _headerMenuService: HeaderMenuService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    public showDropdownMenu(index): void {
        this._headerMenuService.setVisibilityDropdownMenu(index);
    }

    public goToUrl(url: string): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

}
