import { ElementRef } from '@angular/core';

export abstract class ScrollToClass {

    constructor(public parentEl: ElementRef) {
    }

    scrollToSection(className, speed: number = 300, offsetTop: number = null) {
        const componentEl = this.parentEl.nativeElement;

        const targetSection = document.getElementsByClassName(className)[0] as HTMLElement;

        // leave if not found.
        if (targetSection === undefined || targetSection === null) {
            return;
        }

        const targetBounds = targetSection.getBoundingClientRect();
        const isTargetInViewport = targetBounds.top > 56 && targetBounds.bottom < window.innerHeight;

        if (isTargetInViewport && targetBounds.top <= window.innerHeight / 4) {
            //  do nothing
            // IF target section is completely visible
            // AND it's top is on the first square of the screen's height (good visible)
        } else {
            // scroll to target component
            this.scrollTo(
                componentEl,
                targetSection.offsetTop,
                speed,
                offsetTop);
        }
    }

    private scrollTo(element, to, duration, offsetTop) {
        const start = window.pageYOffset || element.scrollTop || document.body.scrollTop || 0;
        const headerHeight = 56;
        const change = offsetTop === null ? to - start - headerHeight : offsetTop;
        let currentTime = 0;
        const increment = 20;

        const easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) {
                return c / 2 * t * t + b;
            }
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        const animateScroll = function () {
            currentTime += increment;
            window.scrollTo(0, easeInOutQuad(currentTime, start, change, duration));

            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
}
