import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-founders',
    templateUrl: './section-founders.component.html',
    styleUrls: ['./section-founders.component.scss']
})
export class SectionFoundersComponent {
    @Input() public loadingImages: boolean;

    public founders = [
        {
            img: 'san-feng',
            name: 'San Feng',
            position: 'CEO & Fundador'
        },
        {
            img: 'juan-manuel',
            name: 'Juan M. Parraguez',
            position: 'CTO (Chief Technology Officer)'
        },
        {
            img: 'miguel-gonzalez',
            name: 'Miguel González',
            position: 'CPO (Chief Product Officer)'
        },
        {
            img: 'enrique-alvarez',
            name: 'Enrique Álvarez',
            position: 'CRO (Chief Revenue Officer)'
        }
    ]
}
