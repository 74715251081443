import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Haulmer';

    constructor(
        private _router: Router
    ) { }

    public isShowHeader(): boolean {
        return this._router.url !== '/error';
    }
}
