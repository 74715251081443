import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-about-haulmer',
    templateUrl: './about-haulmer.component.html',
    styleUrls: ['./about-haulmer.component.scss']
})
export class AboutHaulmerComponent {
    @Input() public loadingImage: boolean;

}
