import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-objectives',
    templateUrl: './section-objectives.component.html',
    styleUrls: ['./section-objectives.component.scss']
})
export class SectionObjectivesComponent {
    @Input() public loadingImages: boolean;
}
