import { PipeTransform, Pipe } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Pipe({ name: 'currencyLocale' })
export class CurrencyLocalePipe implements PipeTransform {

    constructor() {
    }

    transform(value, args: string[]): string {

        return formatCurrency(value,
            'es-CL',
            '',
            'CLP',
            '1.0-0').trim();
    }
}
