import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeaderMenuService {
    public modalComponentRef!: ComponentRef<any>;
    private _mobileMenuSubscriber!: Subject<string>;

    // dropdown menu
    private _currentDropdownMenu$ = new Subject<number|boolean>();
    public showDropdownMenu = this._currentDropdownMenu$.asObservable();

    constructor(
        private resolver: ComponentFactoryResolver
    ) { }

    public openModal(entry: ViewContainerRef, component: Type<any>, data?: any): Observable<any> {
        entry.clear();
        const factory = this.resolver.resolveComponentFactory(component);
        this.modalComponentRef = entry.createComponent(factory);
        this._mobileMenuSubscriber = new Subject<string>();

        if (data) {
            this.modalComponentRef.instance.currentData = data;
        }

        return this._mobileMenuSubscriber.asObservable();
    }

    public closeModal(entry: ViewContainerRef): void {
        entry.clear();
        this._mobileMenuSubscriber.complete();
        this.modalComponentRef.destroy();
    }

    public setVisibilityHeaderNav(
        entry: ViewContainerRef,
        component: Type<any>,
        visibility?: boolean,
        data?: any
    ): void {
        entry.clear();

        if (visibility) {
            const factory = this.resolver.resolveComponentFactory(component);
            const componentRef = entry.createComponent(factory);

            if (data) {
                componentRef.instance.currentData = data;
            }
        }
    }

    public setVisibilityDropdownMenu(currentMenu: number | boolean): void {
        this._currentDropdownMenu$.next(currentMenu);
    }
}
