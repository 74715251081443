import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { URL } from '../../environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {
    public URL = URL;

    public showImage1 = false;
    public showImage2 = false;
    public isShowVideo = false;
    public currentVideo = null;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService
    ) {}

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    public getCurrentVideo(video: string): void {
        this.currentVideo = video;
        this.isShowVideo = true;
    }

    private _setMeta(): void {
        this._meta.setTitle('Las soluciones tecnológicas de Haulmer para negocios de emprendedores');
        this._meta.setTag('description', 'Las soluciones tecnológicas de Haulmer: pagos con tarjetas, boleta electrónica, factura electrónica, firma electrónica hosting y máquinas virtuales.');
        this._meta.setTag('keywords', 'punto de venta, pagos con tarjetas, emprendedores, factura electrónica, boleta electrónica, documentos electrónicos, firma electrónica, certificado digital, hosting, dominios, máquinas virtuales, ssd cloud server, negocios, catálogo, inventario, tuu, chilefirmas, bluehosting, openfactura, opencloud');
        this._meta.setTag('author', 'Haulmer');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HAULMER_WEBSITE + 'assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Las soluciones tecnológicas de Haulmer para negocios de emprendedores');
    }
}
