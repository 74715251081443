import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

interface IDataTab {
    nav_button: { text: string; icon: string };
    logo: string;
    suptitle: string;
    title: string;
    subtitle?: string;
    text: Array<string>;
    button: {
        id?: string;
        text: string;
        url: string;
    };
    img: string;
}

@Component({
    selector: 'app-resource-tabs',
    templateUrl: './resource-tabs.component.html',
    styleUrls: ['./resource-tabs.component.scss']
})
export class ResourceTabsComponent implements OnInit {
    @Input() public loadingImages: boolean;

    data: Array<IDataTab> = [
        {
            nav_button: {
                text: 'Pagos con tarjetas',
                icon: 'ic-sprite-credit-card'
            },
            logo: 'logo-tuu',
            suptitle: 'Pago con tarjetas - TUU',
            title: 'Máquina para recibir pagos con tarjetas <span class="color-accent-primary">desde $59.900 + IVA</span>',
            text: [
                'Comisión única para crédito y débito.',
                'Abono flexible desde 1 día hábil.',
                'Emisión de boleta electrónica ilimitada.',
                'Catálogo e inventario totalmente gratis.'
            ],
            button: {
                id: 'haulmer-h-tuu',
                text: 'Ver más información',
                url: 'https://tuu.cl/'
            },
            img: 'preview-pos-devices'
        },
        {
            nav_button: {
                text: 'Factura electrónica',
                icon: 'ic-sprite-file-check-alt'
            },
            logo: 'logo-openfactura',
            suptitle: 'Facturación electrónica - Openfactura',
            title: 'Automatiza la emisión de documentos por solo <span class="color-accent-primary">$30.000 + IVA al mes.</span>',
            subtitle: '(Facturado anual)',
            text: [
                'Factura electrónica para tu ecommerce.',
                'Integración con Shopify, Prestashop, Jumpseller y otros.',
                'Emisión masiva de documentos con google sheets.'
            ],
            button: {
                id: 'haulmer-h-of',
                text: 'Visitar sitio web',
                url: 'https://openfactura.cl/'
            },
            img: 'preview-workspace'
        },
        {
            nav_button: {
                text: 'Firma electrónica',
                icon: 'ic-sprite-rs-feather'
            },
            logo: 'logo-chilefirmas',
            suptitle: 'Firma electrónica - Chilefirmas',
            title: 'Firma electrónica en 15 minutos desde <span class="color-accent-primary">$11.790 + IVA.</span>',
            text: [
                '100% compatible con SII.',
                '60 días de garantía.',
                'Planes desde 1 a 3 años.'
            ],
            button: {
                id: 'haulmer-h-cf',
                text: 'Visitar sitio web',
                url: 'https://chilefirmas.cl/'
            },
            img: 'preview-electronic-signature'
        },
        {
            nav_button: {
                text: 'Hosting y dominios',
                icon: 'ic-sprite-server-network'
            },
            logo: 'logo-bluehosting',
            suptitle: 'Hosting y dominios',
            title: 'Alcanza un nivel superior con tu sitio web desde <br> <span class="color-accent-primary">$38.900 + IVA anual.</span>',
            text: [
                'Registra dominios .CL, .COM y más.',
                'Cuentas de correos y bases de datos ilimitadas.',
                'Data center ubicado en Chile.'
            ],
            button: {
                id: 'haulmer-h-bh',
                text: 'Visitar sitio web',
                url: 'https://bluehosting.cl/'
            },
            img: 'preview-hosting-domains'
        },
        {
            nav_button: {
                text: 'Máquinas virtuales',
                icon: 'ic-sprite-cloud-share'
            },
            logo: 'logo-opencloud',
            suptitle: 'Máquinas virtuales - Opencloud',
            title: 'SSD Cloud Server desde <span class="color-accent-primary">$2.500 + IVA mensual.</span>',
            text: [
                'Control total del servidor a través de cPanel.',
                'Modo de rescate para revertir y solucionar problemas.',
                'Servidores de alto rendimiento Dell R920.',
                'Data center propio ubicado en Chile.'
            ],
            button: {
                id: 'haulmer-h-oc',
                text: 'Visitar sitio web',
                url: 'https://opencloud.cl/'
            },
            img: 'preview-virtual-machines'
        }
    ];

    currentObj = 0;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit(): void {
    }

    public goToUrl(url): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

}
