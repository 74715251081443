import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-our-values',
    templateUrl: './section-our-values.component.html',
    styleUrls: ['./section-our-values.component.scss']
})
export class SectionOurValuesComponent {
    @Input() public loadingImages: boolean;
}
