import { Component, EventEmitter, Inject, Output, PLATFORM_ID, ViewChild, ViewContainerRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

import { HeaderMenuService } from '../../services/header-menu.service';
import { MobileMenuInnerComponent } from './mobile-menu-inner/mobile-menu-inner.component';
import { DATA_DROPDOWN_MENU, IDropdown } from '../header-data';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {
    @ViewChild('innerMenu', { read: ViewContainerRef }) innerMenu!: ViewContainerRef;
    @Output() closeEventParent = new EventEmitter();

    private _subscriptionMobileMenuInnerOpen!: Subscription;
    private _subscriptionMobileMenuInnerClose!: Subscription;

    public dataInnerMenu: Array<IDropdown> = DATA_DROPDOWN_MENU;

    constructor(
        private _headerMenuService: HeaderMenuService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    public close(): void {
        this.closeEventParent.emit();
    }

    public showInnerMenu(currentMenu: number): void {
        this._subscriptionMobileMenuInnerOpen = this._headerMenuService.openModal(
            this.innerMenu, MobileMenuInnerComponent, this.dataInnerMenu[currentMenu]
        ).subscribe();

        this._subscriptionMobileMenuInnerClose = this._headerMenuService.modalComponentRef.instance.closeEventChild
            .subscribe(v => {
                if (v === 'close') {
                    this.close();
                } else {
                    this._headerMenuService.closeModal(this.innerMenu);
                }
                this._subscriptionMobileMenuInnerOpen.unsubscribe();
                this._subscriptionMobileMenuInnerClose.unsubscribe();
            });
    }

    public goToUrl(url: string): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

}
