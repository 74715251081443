export interface IFooter {
    menu: Array<{ title: string; options: Array<IFooterOption> }>
}

export interface IFooterOption {
    name: string;
    route?: string;
    url?: string;
    scroll_to?: string;
}

export const DATA_FOOTER: Array<IFooter> = [
    {
        menu: [
            {
                title: 'sobre haulmer',
                options: [
                    {
                        name: 'Quienes somos',
                        route: '/quienes-somos/.',
                    },
                    {
                        name: 'Productos',
                        scroll_to: 'products-ts',
                    },
                    {
                        name: 'Testimonios',
                        scroll_to: 'reviews-ts',
                    },
                    {
                        name: 'Trabaja con nosotros',
                        url: 'https://www.linkedin.com/company/haulmer/',
                    },
                ],
            },
        ],
    },
    {
        menu: [
            {
                title: 'productos',
                options: [
                    {
                        name: 'Pago',
                        url: 'https://www.tuu.cl/',
                    },
                    {
                        name: 'Facturación electrónica',
                        url: 'https://www.openfactura.cl/',
                    },
                    {
                        name: 'Firma electrónica',
                        url: 'https://www.chilefirmas.cl/',
                    },
                    {
                        name: 'Web Hosting',
                        url: 'https://www.bluehosting.cl/',
                    },
                    {
                        name: 'Cloud Server',
                        url: 'https://www.opencloud.cl/',
                    },
                ],
            },
        ],
    },
    {
        menu: [
            {
                title: 'programas',
                options: [
                    {
                        name: 'Revendedores',
                        url: 'https://www.tuu.cl/revendedores/',
                    },
                    {
                        name: 'Partners',
                        url: 'https://www.tuu.cl/partner/',
                    },
                ],
            },
            {
                title: 'haulmer info',
                options: [
                    {
                        name: 'Blog',
                        url: 'https://www.tuu.cl/articulos/',
                    },
                    {
                        name: 'Noticias',
                        url: 'https://www.haulmer.com/news/',
                    },
                    {
                        name: 'Trabajo',
                        url: 'https://www.linkedin.com/company/haulmer/',
                    },
                ],
            },
        ],
    },
    {
        menu: [
            {
                title: 'haulmer soporte',
                options: [
                    {
                        name: 'Help',
                        url: 'https://help.tuu.cl/'
                    },
                    {
                        name: 'Video Tutoriales',
                        url: 'https://www.youtube.com/channel/UC9zdjjv0lgG0UtxWVZEJN_w'
                    },
                    {
                        name: 'Comunidad',
                        url: 'https://communityinviter.com/apps/haulmer/haulmer'
                    },
                    {
                        name: 'Ideas',
                        url: 'https://ideas.haulmer.com/'
                    }
                ]
            }
        ]
    }
];
