export interface IDropdown {
    menu_name: string;
    columns: Array<IDropdownColumn>;
}

export interface IDropdownColumn {
    menu: Array<IDropdownColumnMenu>;
}

export interface IDropdownColumnMenu {
    title: string;
    list: Array<{ caption: string; text: string; link: string; }>
}

export const DATA_DROPDOWN_MENU: Array<IDropdown> = [
    {
        menu_name: 'Productos',
        columns: [
            {
                menu: [
                    {
                        title: 'negocios',
                        list: [
                            {
                                caption: 'Pago',
                                text: '<b>TUU</b> • Recibe pagos con tarjetas de débito y crédito.',
                                link: 'https://www.tuu.cl/'
                            },
                            {
                                caption: 'Boleta electrónica',
                                text: 'Emite boleta electrónica sin límites.',
                                link: 'https://www.tuu.cl/boleta-electronica'
                            },
                            {
                                caption: 'Facturación electrónica',
                                text: '<b>Openfactura</b> • Sistema de facturación electrónica sin topes de emisión.',
                                link: 'https://www.openfactura.cl/'
                            },
                            {
                                caption: 'Firma electrónica',
                                text: '<b>ChileFirmas</b> • Certificado digital con validez legal.',
                                link: 'https://www.chilefirmas.cl/'
                            }
                        ]
                    }
                ]
            },
            {
                menu: [
                    {
                        title: 'servicios web',
                        list: [
                            {
                                caption: 'Web Hosting',
                                text: '<b>Bluehosting</b> • Servicio web hosting y registro de dominio.',
                                link: 'https://www.bluehosting.cl/web-hosting/'
                            },
                            {
                                caption: 'Máquinas virtuales',
                                text: '<b>Opencloud</b> • SSD Cloud Server para developers con la mejor latencia de Chile.',
                                link: 'https://www.opencloud.cl/'
                            }
                        ]
                    },
                    {
                         title: 'Revendedores',
                         list: [
                             {
                                 caption: 'TUU Revendedor',
                                 text: 'Integra los servicios de Haulmer a tu negocio y <b>aumenta tu propuesta de valor.</b>',
                                 link: 'https://www.tuu.cl/revendedores'
                             }
                         ]
                     }
                ]
            }
        ]
    },
    {
        menu_name: 'Comunidad',
        columns: [
            {
                menu: [
                    {
                        title: 'COMUNIDAD HAULMER',
                        list: [
                            {
                                caption: 'Ayuda',
                                text: 'Aprende a utilizar nuestros productos.',
                                link: 'https://help.tuu.cl/'
                            },
                            {
                                caption: 'Comunidad',
                                text: 'Interactúa con nuestro equipo en Slack.',
                                link: 'https://communityinviter.com/apps/haulmer/haulmer'
                            },
                            {
                                caption: 'Ideas',
                                text: 'Ayúdanos a mejorar HAULMER.',
                                link: 'https://ideas.haulmer.com/'
                            }
                        ]
                    }
                ]
            },
            {
                menu: [
                    {
                        title: 'INFO HAULMER',
                        list: [
                            {
                                caption: 'Blog',
                                text: 'Consulta nuestros artículos.',
                                link: 'https://www.tuu.cl/articulos/'
                            },
                            {
                                caption: 'Noticias',
                                text: 'Conoce nuestras últimas novedades.',
                                link: 'https://www.haulmer.com/news/'
                            },
                            {
                                caption: 'Trabajo',
                                text: 'Sé parte de nuestro equipo.',
                                link: 'https://www.linkedin.com/company/haulmer/'
                            }
                        ]
                    }
                ]

            }
        ]
    }
];
