// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export class URL {
    //DEVELOP.
    public static readonly HAULMER_WEBSITE = 'https://www.haulmer.dev/';
    public static readonly HAULMER_FACEBOOK = 'https://www.facebook.com/HaulmerLAT/';
    public static readonly HAULMER_TWITTER = 'https://twitter.com/Haulmerlat';
    public static readonly HAULMER_YOUTUBE = 'https://www.youtube.com/channel/UC9zdjjv0lgG0UtxWVZEJN_w';
    public static readonly HAULMER_LINKEDIN = 'https://www.linkedin.com/company/haulmer/';
    public static readonly HAULMER_INST = 'https://www.instagram.com/haulmerlat/?hl=es-la';
    public static readonly HAULMER_WORK_FOR_US = 'mailto:career@haulmer.com';
    public static readonly HAULMER_ARTICLE = 'https://www.haulmer.com/articulos/';
    public static readonly HAULMER_NEWS = 'https://www.haulmer.com/news/';
    public static readonly HAULMER_HELP = 'https://help.tuu.cl/';
    public static readonly CHILEFIRMAS_WEBSITE = 'https://www.chilefirmas.cl/';
    public static readonly OPENFACTURA_WEBSITE = 'https://www.openfactura.cl/';
    public static readonly BLUEHOSTING_WEBSITE = 'https://www.bluehosting.cl/web-hosting/';
    public static readonly HAULMER_SLACK = 'https://haulmer.slack.com/';
}
