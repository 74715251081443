import { Component } from '@angular/core';

@Component({
    selector: 'app-about-us-first-screen',
    templateUrl: './about-us-first-screen.component.html',
    styleUrls: ['./about-us-first-screen.component.scss']
})
export class AboutUsFirstScreenComponent {

}
