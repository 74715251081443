import { Component } from '@angular/core';

@Component({
    selector: 'app-first-screen-home',
    templateUrl: './first-screen-home.component.html',
    styleUrls: ['./first-screen-home.component.scss']
})
export class FirstScreenHomeComponent {

}
