import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { URL } from '../../environments/environment';

@Component({
    selector: 'app-about-us-page',
    templateUrl: './about-us-page.component.html',
    styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {

    public URL = URL;

    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService
    ) { }

    public ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta(): void {
        this._meta.setTitle('Quienes somos Haulmer: Soluciones tecnológicas para emprendedores');
        this._meta.setTag('description', 'Conoce quiénes somos en Haulmer, sus fundadores, su historia y todo sobre la empresa que desarrolla soluciones tecnológicas para emprendedores ');
        this._meta.setTag('keywords', 'quienes somos haulmer, conócenos haulmer, mision haulmer, vision haulmer, fundadores haulmer, corporativo haulmer, linkedin haulmer, tuu, chilefirmas, bluehosting, openfactura, opencloud, soluciones tecnológicas, emprendedores');
        this._meta.setTag('author', 'Haulmer');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HAULMER_WEBSITE + 'assets/images/og/og-about-us.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Quienes somos Haulmer: Soluciones tecnológicas para emprendedores');
    }
}
