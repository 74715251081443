import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'app-about-product',
    templateUrl: './about-product.component.html',
    styleUrls: ['./about-product.component.scss']
})
export class AboutProductComponent implements OnInit {
    @Input() public loadingImage: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit(): void {
    }

    public goToUrl(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open('https://www.tuu.cl/', '_blank');
        }
    }
}
