import {
    Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild, ViewContainerRef
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Subscription } from "rxjs";

import { HeaderMenuService } from "../services/header-menu.service";
import { HeaderNavComponent } from "./header-nav/header-nav.component";
import { MobileMenuComponent } from "./mobile-menu/mobile-menu.component";
import { DropdownMenuComponent } from "./dropdown-menu/dropdown-menu.component";
import { DATA_DROPDOWN_MENU, IDropdown } from "./header-data";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    @ViewChild('headerNav', { read: ViewContainerRef }) headerNav!: ViewContainerRef;
    @ViewChild('dropdownMenu', { read: ViewContainerRef }) dropdownMenu!: ViewContainerRef;
    @ViewChild('mobileMenu', { read: ViewContainerRef }) mobileMenu!: ViewContainerRef;

    public isHeaderFixed: boolean;
    public isShowHeaderNav: boolean;
    public dataDropdownMenu: Array<IDropdown> = DATA_DROPDOWN_MENU;

    // mobile menu
    private _subscriptionMobileMenuOpen!: Subscription;
    private _subscriptionMobileMenuClose!: Subscription;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isShowHeaderNav = event.target.innerWidth > 992;
        this.visibilityHeaderNav();
    }

    @HostListener('window:scroll')
    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isHeaderFixed = window.scrollY >= 48;
        }
    }

    constructor(
        private _renderer: Renderer2,
        private _headerMenuService: HeaderMenuService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isShowHeaderNav = window.innerWidth > 992;
            this.visibilityHeaderNav();
        }

        // dropdown menu
        this._headerMenuService.showDropdownMenu.subscribe(v => {
            if (typeof v === 'number') {
                this._renderer.addClass(document.body, 'open-menu');
                this._headerMenuService.setVisibilityHeaderNav(
                    this.dropdownMenu, DropdownMenuComponent,
                    true,
                    this.dataDropdownMenu[v]
                )
            } else {
                this._renderer.removeClass(document.body, 'open-menu');
                this._headerMenuService.setVisibilityHeaderNav(this.dropdownMenu, DropdownMenuComponent, false)
            }
        });
    }

    public visibilityHeaderNav(): void {
        setTimeout(() => {
            this._headerMenuService.setVisibilityHeaderNav(
                this.headerNav, HeaderNavComponent, this.isShowHeaderNav
            )
        }, 0);
    }

    public openMobileMenu(): void {
        setTimeout(() => {
            this._renderer.addClass(document.body, 'open-menu');
        }, 100);

        this._subscriptionMobileMenuOpen = this._headerMenuService.openModal(
            this.mobileMenu, MobileMenuComponent
        ).subscribe();

        this._subscriptionMobileMenuClose = this._headerMenuService.modalComponentRef.instance.closeEventParent
            .subscribe(() => {
                this.closeMenu();
            });
    }

    public closeMenu(): void {
        this._renderer.removeClass(document.body, 'open-menu');

        if (this.isShowHeaderNav) {
            this._headerMenuService.setVisibilityDropdownMenu(false);
        } else {
            setTimeout(() => {
                this._headerMenuService.closeModal(this.mobileMenu);
                this._subscriptionMobileMenuOpen.unsubscribe();
                this._subscriptionMobileMenuClose.unsubscribe();
            }, 400);
        }
    }

}
