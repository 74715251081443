import { Component, OnInit, PLATFORM_ID, Inject, Input, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { CONTACTS, IContact } from '../../../../../common/contacts.constants';
import { URL } from '../../../environments/environment';
import { DATA_FOOTER, IFooter, IFooterOption } from './footer-data';
import { ScrollToClass } from '../scrollTo.class';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ScrollToClass implements OnInit {
    @Input() public loadingImages: boolean;

    public URL = URL;

    public dataFooter: Array<IFooter>;

    public contactList: Array<IContact> = CONTACTS;
    public countrySelected: IContact;

    public socialList = [
        {
            icon: 'ic-sprite-fb-circle',
            url: URL.HAULMER_FACEBOOK
        },
        {
            icon: 'ic-sprite-tw-circle',
            url: URL.HAULMER_TWITTER
        },
        {
            icon: 'ic-sprite-youtube-circle',
            url: URL.HAULMER_YOUTUBE
        },
        {
            icon: 'ic-sprite-linkedin-circle',
            url: URL.HAULMER_LINKEDIN
        },
        {
            icon: 'ic-sprite-inst-circle',
            url: URL.HAULMER_INST
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private _el: ElementRef,
        private router: Router
    ) {
        super(_el);
        this.dataFooter = DATA_FOOTER;
    }

    ngOnInit(): void {
        this.countrySelected = this.contactList.filter(c => c.country_code === 'cl')[0];
    }

    public selectedCountry(country: string): void {
        this.countrySelected = this.contactList.filter((item) => item.country === country)[0];
    }

    public goToUrl(option: IFooterOption): void {
        if (option.url) {
            if (isPlatformBrowser(this.platformId)) {
                window.open(option.url, '_blank');
            }
        } else if (option.scroll_to) {
            this.navigateToSection(option.scroll_to);
        } else {
            return;
        }
    }

    public navigateToSection(className: string): void {
        this.router.navigate(['/']);
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }
}
