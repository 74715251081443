import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-how-we-work',
    templateUrl: './section-how-we-work.component.html',
    styleUrls: ['./section-how-we-work.component.scss']
})
export class SectionHowWeWorkComponent {
    @Input() public loadingImages: boolean;
}
